<template>
  <div>
    <div style="display: flex">
      <x-label v-if="label" tag="label" for="location">{{ label }}</x-label>
      <IconLocation
        v-if="useNearestLocation"
        class="location-icon"
        :data-loading="loadingLocationsList || loadingNearestLocation"
        @click="loadNearestLocation({ saveModel: true })"
      />
    </div>
    <FormInput
      id="location"
      ref="input"
      :key="nearestLocationUpdatedAt"
      v-model="model"
      name="location"
      :list="`locations-${locationType}`"
      style="font-family: LuciferSans; color: var(--color-primary)"
      required
      :validator="
        value => locations.flat.some(({ displayName }) => displayName === value)
      "
      :value="value"
      readonly
      @focus="$refs.locationsModal.show()"
      @click="$refs.locationsModal.show()"
    />
    <LocationInputList
      ref="locationsModal"
      :placeholder="placeholder"
      :active-id="value"
      :locations="locations.all"
      :loading-search-locations="loadingSearchLocations"
      @select="selectLocation"
      @input="searchLocations"
    />
  </div>
</template>

<script>
import IconLocation from '@/components-rf/icons/common/IconLocation.vue'
import { mapActions, mapGetters } from 'vuex'
import FormInput from '../../components/utils/FormInput.vue'
import { LOCATION } from '../../constants/LOCATION'
import { debounce } from '../../other/utils/helpers/throttle'
import LocationInputList from './LocationInputList.vue'

export default {
  name: 'LocationInput',
  components: { IconLocation, FormInput, LocationInputList },
  props: {
    value: String,
    label: String,
    placeholder: String,
    useNearestLocation: Boolean,
    locationType: String,
    savedLocation: Object,
  },
  data() {
    return {
      model: '',
      nearestLocationUpdatedAt: 0,
      loadingLocationsList: false,
      loadingSearchLocations: false,
      loadingNearestLocation: false,
    }
  },
  created() {
    this.init()
  },
  watch: {
    value: {
      async handler(locationId) {
        if (!locationId || !this.locations.all.length) return
        const location = this.findById(locationId)
        this.model = location ? location.displayName : ''
      },
    },
    model: {
      async handler(displayName) {
        if (!this.locations.all.length) return
        const location = this.findByName(displayName)
        this.$emit('input', location ? location.id : '')
      },
    },
  },
  computed: {
    ...mapGetters('LocationModule', [
      'findLocationByName',
      'findLocationById',
      'allLocations',
      'flatLocations',
    ]),
    locations: vm => ({
      all: vm.allLocations[vm.locationType],
      flat: vm.flatLocations[vm.locationType],
    }),
    isPaginated() {
      return LOCATION.PAGINATION[this.locationType]
    },
  },
  methods: {
    ...mapActions('LocationModule', ['getLocations', 'getNearestLocation']),
    async init() {
      const initialLocationId = this.value
      if (!this.locations.all.length && !this.savedLocation) {
        this.$emit('input', '')
        await this.fetchLocations()
      } else if (!this.locations.all.length && this.savedLocation) {
        this.fetchLocations()
      }

      const location = this.savedLocation
        ? this.savedLocation
        : initialLocationId
        ? this.findById(initialLocationId)
        : await this.loadNearestLocation({})
      if (!location) return

      this.$emit('input', location.id)
      this.model = location.displayName
    },
    fetchLocations() {
      this.loadingLocationsList = true
      return this.getLocations({ type: this.locationType }).finally(
        () => (this.loadingLocationsList = false),
      )
    },
    searchLocations: debounce(function (name) {
      if (!this.isPaginated) return
      if (!name || name.length < 3) return
      this.loadingSearchLocations = true
      this.getLocations({ type: this.locationType, search: name }).finally(
        () => (this.loadingSearchLocations = false),
      )
    }, 333),
    findByName(name) {
      if (this.savedLocation?.displayName === name) return this.savedLocation
      return this.findLocationByName(name, this.locationType)
    },
    findById(id) {
      if (this.savedLocation?.id === id) return this.savedLocation
      return this.findLocationById(id, this.locationType)
    },
    loadNearestLocation({ saveModel }) {
      if (!this.useNearestLocation) return
      this.loadingNearestLocation = true
      return this.getNearestLocation()
        .then(location => {
          if (location && saveModel) {
            this.model = location.displayName
            this.nearestLocationUpdatedAt = Date.now()
          }
          return location
        })
        .finally(() => (this.loadingNearestLocation = false))
    },
    selectLocation(location) {
      this.$emit('select', location)
      this.model = location.displayName
      this.$refs.input.$el.value = location.displayName
      this.$refs.input.handleInput()
    },
  },
}
</script>

<style scoped lang="scss">
.location-icon {
  margin-left: 0.5rem;
  color: var(--color-primary);
  transition: color 0.2s ease-in-out;
  &:hover {
    color: var(--color-secondary);
  }
  &:not([data-loading]) {
    cursor: pointer;
  }
  &[data-loading] {
    color: var(--color-grey);
  }
}
</style>
