<template>
  <div
    v-show="isShown"
    class="location-list"
    data-hide-on-click
    @click="onWrapperClick"
  >
    <div class="container-xs">
      <div class="location-list-input-row">
        <AppNavButton
          icon="back"
          style="width: 30px; height: 30px"
          @click="hide"
        />
        <div style="display: grid; grid-template-columns: 1fr 35px">
          <input
            ref="locationInput"
            v-model="search"
            type="text"
            :placeholder="placeholder"
            style="
              font-family: LuciferSans;
              color: var(--color-primary);
              font-size: 16px;
            "
            @input="$event.target.composing = false"
          />
          <div style="padding: 10px; display: flex; align-items: center">
            <Transition name="fade">
              <AppLoader
                v-if="loadingSearchLocations || !locations.length"
                :size="20"
              />
            </Transition>
          </div>
        </div>
        <AppNavButton
          icon="close"
          style="width: 30px; height: 30px"
          @click="clearInput"
        />
      </div>
    </div>
    <div class="location-list-items-row" data-hide-on-click>
      <div class="container-xs">
        <template v-for="location in filteredLocations">
          <LocationInputListItem
            :key="location.id"
            class="location-list-item"
            :data-id="location.id"
            :active-id="activeId"
            :location="location"
            @click="select(location)"
          />
          <LocationInputListItem
            v-for="sublocation in location.sublocationsList"
            :key="sublocation.id"
            class="location-list-item"
            style="padding-left: 2rem"
            :data-id="sublocation.id"
            :active-id="activeId"
            :location="sublocation"
            @click="select(sublocation)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppNavButton from '../AppNavButton.vue'
import LocationInputListItem from './LocationInputListItem.vue'
import Fuse from 'fuse.js'
import { scrollIntoView } from '../../other/utils/scrollIntoView'
import AppLoader from '../AppLoader.vue'

export default {
  name: 'LocationInputModal',
  components: { LocationInputListItem, AppNavButton, AppLoader },
  props: {
    locations: Array,
    activeId: String,
    placeholder: String,
    loadingSearchLocations: Boolean,
  },
  data() {
    return {
      isShown: false,
      hideDelay: 200,
      openedAt: Date.now(),
      search: '',
    }
  },
  watch: {
    isShown(isShown) {
      if (!isShown) return
      this.search = ''
      this.focusInput()
      this.scrollTo(this.activeId)
    },
    activeId(id) {
      this.scrollTo(id)
    },
    search(name) {
      if (name) this.$emit('input', name)
    },
  },
  computed: {
    searchLocations: vm =>
      vm.locations.reduce((a, location) => {
        a.push(location)
        const sublocations = location.sublocationsList.map(sublocation => ({
          ...sublocation,
          parent: location,
        }))
        a.push(...sublocations)
        return a
      }, []),
    filteredLocations() {
      if (!this.search) return this.locations
      const fuse = new Fuse(this.searchLocations, {
        findAllMatches: true,
        threshold: 0.25,
        keys: [
          { name: 'iataCode', weight: 2 },
          { name: 'display.name', weight: 1.5 },
          { name: 'display.country' },
        ],
      })
        .search(`${this.search}`)
        .map(result => result.item?.parent ?? result.item)
        .reduce((a, c) => {
          if (!a.some(el => el.id === c.id)) a.push(c)
          return a
        }, [])

      return fuse
    },
  },
  methods: {
    show() {
      this.isShown = true
      this.openedAt = Date.now()
    },
    hide() {
      if (Date.now() > this.openedAt + this.hideDelay) this.isShown = false
    },
    scrollTo: id => id && scrollIntoView(`[data-id='${id}']`),
    clearInput() {
      this.search = ''
      this.focusInput()
    },
    focusInput() {
      this.$nextTick(() => this.$refs.locationInput.focus())
    },
    select(location) {
      this.$emit('select', location)
      this.hide()
    },
    onWrapperClick(e) {
      if (e.target.getAttribute('data-hide-on-click') === '') this.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.location-list {
  --input-height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: 0.5rem;
  background: var(--color-light);
}
.location-list-input-row {
  border-bottom: 1px solid #ddd;
  height: var(--input-height);
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  align-items: center;
}

.location-list-items-row {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: calc(100% - var(--input-height));
  overflow-y: auto;
}
.location-list-item[data-active] {
  background: #f001;
}
.lds-dual-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  margin: 0px;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  border-color: var(--color-primary) transparent var(--color-primary)
    transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
