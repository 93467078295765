<template>
  <button
    class="location-input-list-item"
    :data-active="location.id === activeId"
    @click.prevent="$emit('click')"
  >
    <component :is="icon(location.locationType)" style="justify-self: center" />
    <div class="location-input-list-item-text">
      <p style="font-weight: bold">{{ location.display.name }}</p>
      <p style="margin-top: 3px">{{ location.display.regionCountry }}</p>
    </div>
  </button>
</template>

<script>
import IconLocationPlane from '../icons/location-input/IconLocationPlane.vue'
import IconLocationPin from '../icons/location-input/IconLocationPin.vue'
import { LocationTypeEnum } from '../../../../util/gen/proto/commons/enum_pb'

export default {
  name: 'LocationInputModal',
  props: { location: Object, activeId: String },
  methods: {
    icon(locationType) {
      return locationType === LocationTypeEnum.AIRPORT ||
        locationType === LocationTypeEnum.METROPOLITAN_AREA
        ? IconLocationPlane
        : IconLocationPin
    },
  },
  components: { IconLocationPlane, IconLocationPin },
}
</script>

<style scoped lang="scss">
.location-input-list-item {
  display: grid;
  padding: 0.5rem 0.5rem;
  grid-template-columns: 30px auto;
  gap: 1rem;
  align-items: center;
  text-align: start;
  width: 100%;
  transition: background 0.2s ease;
  cursor: pointer;
  @media (hover: hover) {
    &:hover,
    &:focus {
      background: #f001;
    }
  }
}
.location-input-list-item[data-active] {
  background: #f001;
}
.location-input-list-item-text {
  font-family: DDin;
  font-size: 15px;
  color: #666;
  line-height: 1.2;
}
</style>
